<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="审批单号"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.batchNo" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item
              label="状态"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="审批单状态"
                v-model="queryParams.status"
                style="width: 100%"
              >
                <a-select-option
                  v-for="s in status"
                  :key="s.keyy"
                  :value="s.keyy"
                  >{{ s.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button type="primary" ghost @click="add">新增</a-button
        >
      </div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.batchId"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 900 }"
      >
        <a-table
          slot="expandedRowRender"
          slot-scope="text"
          :columns="innerColumns"
          :data-source="text.allowanceList"
          :rowKey="(record) => record.allowanceId"
          :pagination="false"
        >
        </a-table>
        <template slot="allTotalFee" slot-scope="text, record">
          <div style="display: flex;flex-direction: row;align-items: center">
          <span style="margin-right:10px;">{{text}}</span>
          <a-icon
            v-if="record.approveAllowanceList != null"
            type="eye"
            theme="twoTone"
            twoToneColor="#42b983"
            @click="showFeeDetail(record)"
            title="查看明细"
          ></a-icon>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div v-if="record.status == 3">
            <a-button  size="small" type="primary" ghost @click="toSubmit(record.batchId,'agree')">同意</a-button>
            <a-divider type="vertical" />
            <a-button  size="small" type="danger" ghost @click="toSubmit(record.batchId,'reject')">驳回</a-button>
          </div>
        </template>
      </a-table>
      <!-- 查看费用详情 -->
      <all-total-fee-detail
        :feeData="feeData"
        :feeVisiable="feeVisiable"
        @close="handleFeeDetailClose"
      >
      </all-total-fee-detail>
    </div>
  </a-card>
</template>
<script>
import RangeDate from "@/components/datetime/RangeDate";
import { mapState } from "vuex";
import moment from "moment";
import allTotalFeeDetail from "./allTotalFeeDetail";

export default {
  name: "approveThird",
  components: { RangeDate, allTotalFeeDetail },
  data() {
    return {
      fileList: [],
      importData: [],
      times: "",
      errors: [],
      uploading: false,
      importResultVisible: false,
      advanced: false,
      dataSource: [],
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
      queryParams: {
        queryDate: [
          moment(new Date(), "YYYY-MM-DD").subtract(30, "day"),
          moment(new Date(), "YYYY-MM-DD"),
        ],
        batchNo: '',
        deptName: '',
        status: '3',
      },
      feeVisiable: false,
      loading: false,
      feeData: {},
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.approve_batch_status,
      taskType: (state) => state.dict.dicts.task_task_type,
    }),
    columns() {
      return [
        {
          title: "审批单号",
          dataIndex: "batchNo",
          width: 150,
        },
        {
          title: "年份",
          dataIndex: "year",
          width: 80,
        },
        {
          title: "月份",
          dataIndex: "month",
          width: 80,
        },
        {
          title: "总费用",
          dataIndex: "allTotalFee",
          width: 150,
          scopedSlots: { customRender: "allTotalFee" },
        },
        {
          title: "提交人",
          dataIndex: "submitUsername",
          width: 150,
        },
        {
          title: "提交时间",
          dataIndex: "submitTime",
          width: 160,
          customRender: (text, row, index) => {
            return text == undefined ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "一级审核人",
          dataIndex: "firstCheckUsername",
          width: 150,
        },
        {
          title: "一级审核时间",
          dataIndex: "firstCheckTime",
          width: 160,
          customRender: (text, row, index) => {
            return text == undefined ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "二级审核人",
          dataIndex: "secondCheckUsername",
          width: 150,
        },
        {
          title: "二级审核时间",
          dataIndex: "secondCheckTime",
          width: 160,
          customRender: (text, row, index) => {
            return text == undefined ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "三级审核人",
          dataIndex: "thirdCheckUsername",
          width: 150,
        },
        {
          title: "三级审核时间",
          dataIndex: "thirdCheckTime",
          width: 160,
          customRender: (text, row, index) => {
            return text == undefined ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "四级审核人",
          dataIndex: "fourthCheckUsername",
          width: 150,
        },
        {
          title: "四级审核时间",
          dataIndex: "fourthCheckTime",
          width: 160,
          customRender: (text, row, index) => {
            return text == undefined ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (text, row, index) => {
            for (let _index in this.status) {
              let key = this.status[_index].keyy;
              if (text === key) {
                let val = this.status[_index].valuee;
                let color = this.status[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
          width: 200,
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: 200,
        },
      ];
    },
    innerColumns() {
      return [
        {
          title: "员工编号",
          dataIndex: "empno",
          width: 100,
        },
        {
          title: "用户名",
          dataIndex: "username",
          width: 100,
        },
        {
          title: "部门",
          dataIndex: "deptName",
          width: 150,
        },
        {
          title: "行程开始",
          dataIndex: "beginDate",
          width: 150,
          customRender: (text, row, index) => {
            return (
              moment(text).format("YYYY-MM-DD") + " " + row.beginHour + "时"
            );
          },
        },
        {
          title: "行程结束",
          dataIndex: "endDate",
          width: 150,
          customRender: (text, row, index) => {
            return moment(text).format("YYYY-MM-DD") + " " + row.endHour + "时";
          },
        },
        {
          title: "天数",
          dataIndex: "days",
          width: 80,
        },
        {
          title: "工单",
          dataIndex: "taskNo",
          width: 150,
        },
        {
          title: "合同号",
          dataIndex: "contractNo",
          width: 150,
        },
        {
          title: "客户名称",
          dataIndex: "customer",
          width: 150,
        },
        {
          title: "服务类型",
          dataIndex: "taskType",
          width: 150,
          customRender: (text, row, index) => {
            for (let _index in this.taskType) {
              let key = this.taskType[_index].keyy;
              if (text === key) {
                let val = this.taskType[_index].valuee;
                let color = this.taskType[_index].otherKeyy;
                return <a-badge color={color} text={val}></a-badge>;
              } else {
                continue;
              }
            }
            return taskType;

          },
        },
        // {
        //   title: "早餐补贴",
        //   dataIndex: "breakfastFee",
        //   width: 100,
        // },
        // {
        //   title: "午餐补贴",
        //   dataIndex: "lunchFee",
        //   width: 100,
        // },
        // {
        //   title: "晚餐补贴",
        //   dataIndex: "supperFee",
        //   width: 100,
        // },
        // {
        //   title: "住宿补贴",
        //   dataIndex: "hotelFee",
        //   width: 100,
        // },
        {
          title: "餐补",
          dataIndex: "mealFee",
          width: 100,
        },
        {
          title: "交通费用",
          dataIndex: "carFee",
          width: 100,
        },
        {
          title: "特殊补贴",
          dataIndex: "specialFee",
          width: 100,
        },
        {
          title: "其他费用",
          dataIndex: "otherFee",
          width: 100,
        },
        {
          title: "差旅补贴",
          dataIndex: "workFee",
          width: 100,
        },
      ]
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    moment,
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    search() {
      this.selectData({
        ...this.queryParams,
      });
    },
    handleTableChange(pagination, filters, sorter) {
      this.paginationInfo = pagination;
      this.fetch({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      params.status = '3'
      params.beginDateFrom = this.queryParams.queryDate[0].format("YYYY-MM-DD");
      params.endDateTo = this.queryParams.queryDate[1].format("YYYY-MM-DD");
      delete params.queryDate;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      this.$get("approve-batch", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    selectData(params = {}) {
      this.loading = true;
      params.beginDateFrom = this.queryParams.queryDate[0].format("YYYY-MM-DD");
      params.endDateTo = this.queryParams.queryDate[1].format("YYYY-MM-DD");
      delete params.queryDate;
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize;
      params.pageSize = this.pagination.defaultPageSize;
      params.pageNum = this.pagination.defaultCurrent;
      this.$get("approve-batch", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    add() {
      this.$post("approve-batch").then((r) => {
        if (r.data.code == 200 ) {
          this.$message.success(r.data.message);
        }
        else {
          this.$message.warning(r.data.message);
        }
        this.search();
      });
    },
    toSubmit(batchId,type) {
      let typeTxt = type == 'agree' ? '下级' : '上级'
      let that = this;
      this.$confirm({
        title: "确定" + type + "审批单吗?",
        content: "当您点击确定按钮后，审批单进入" + typeTxt + "审批阶段",
        centered: true,
        onOk() {
          that.$put("approve-batch/third/" + batchId + "/" + type).then(() => {
            that.$message.success("提交成功");
            that.search();
          });
        },
        onCancel() {
          that.selectedRowKeys = [];
        },
      });
    },
    showFeeDetail(record) {
      this.feeData = record
      this.feeVisiable = true
    },
    handleFeeDetailClose() {
      this.feeVisiable = false
    },
    reset() {
      // 重置查询参数
      this.queryParams.batchNo = '';
      this.queryParams.deptName = '';
      this.fetch();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>